import { Injectable, inject } from '@angular/core';
import {
  Database,
  onValue,
  push,
  query,
  ref,
  set,
  limitToLast,
  remove,
  onChildAdded,
  update,
} from '@angular/fire/database';
import { Notifications } from '../interfaces/base-interface';
import ShortUniqueId from 'short-unique-id';
import humanizeDuration from 'humanize-duration';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private rtdb = inject(Database);
  private okToDing: boolean = true;
  public myNotifications: Notifications[] = [];

  constructor() {}

  testSendNotification(
    toUserID: string = 'testuser',
    message = 'test message'
  ) {
    set(ref(this.rtdb, 'users/' + toUserID + '/notifications'), {
      username: 'this name',
      email: 'this email',
      profile_picture: 'this URL',
      testMessage: message,
    }).then(
      () => {
        console.log('sent notification');
      },
      (err) => {
        console.log('error.while sending notification', err);
      }
    );
  }

  sendNotification(
    toUserID: string = 'testuser',
    message = 'test message',
    msgType = 'normal'
  ) {
    const nowDate = new Date();
    const uid = new ShortUniqueId({ length: 10, dictionary: 'alphanum_upper' });
    const notificationID = uid.rnd();
    const notificationsRef = ref(this.rtdb, 'notifications/' + toUserID);
    const newNotificationRef = push(notificationsRef);
    set(newNotificationRef, {
      notificationID: notificationID,
      message: message,
      msgType: msgType,
      status: 'new',
      updateTimestamp: nowDate.getTime(),
    }).then(
      () => {
        console.log('sent notification');
      },
      (err) => {
        console.log('error.while sending notification', err);
      }
    );
  }

  readMyNotifications(userID: string) {
    console.log('read my notifications', userID);
    const myNotificationsRef = ref(this.rtdb, 'notifications/' + userID);
    const recentNotificationsRef = query(myNotificationsRef, limitToLast(10));
    let firstTime = true;
    let gotNotifications = true;
    // read the last 10 notifications once

    // onValue(recentNotificationsRef, (snapshot) => {
    //   this.myNotifications=[];
    //   const nowDate = new Date();
    //   snapshot.forEach((entry)=>{
    //     let data = entry.val();
    //     let thenDate = new Date(data.updateTime);
    //     let duration = humanizeDuration(nowDate.getTime() - thenDate.getTime());
    //     let newEntry:any = Object.assign({}, data, {key:entry.key, duration:duration});
    //     this.myNotifications.push(newEntry);
    //   })
    //   console.log("read my notifications", this.myNotifications);
    //   // if(!firstTime){
    //   //   this.playBellDing();
    //   //   setTimeout(()=>{
    //   //   alert("New notifications received");
    //   //   },2000);
    //   // }else{
    //   //   firstTime = false;
    //   // }
    // }, {
    //   onlyOnce: true
    // });
    // when a new notification is added, play a sound and show an alert
    this.myNotifications = [];

    onChildAdded(myNotificationsRef, (snapshot) => {
      const nowDate = new Date();
      console.log('new notification', snapshot.val(), snapshot.key, snapshot);
      let data = snapshot.val();
      let duration = humanizeDuration(nowDate.getTime() - data.updateTimestamp);
      let newEntry: any = Object.assign({}, data, {
        key: snapshot.key,
        duration: duration,
      });
      this.myNotifications.push(newEntry);
      this.alertNotification();
      if (data.status == 'unread') {
        this.markAsReceived(userID, snapshot.key);
      }
    });
    setTimeout(() => {
      firstTime = false;
    }, 3000);
  }

  deleteNotification(userID: string, key: string) {
    console.log('delete notification', userID, key);
    const myNotificationRef = ref(
      this.rtdb,
      'notifications/' + userID + '/' + key
    );
    remove(myNotificationRef);
    // delete the notification from the list
    this.myNotifications = this.myNotifications.filter((entry) => {
      return entry.key !== key;
    });
  }

  alertNotification() {
    // okToDing is a flag to prevent the alert from being triggered too frequently

    if (this.okToDing) {
      this.playBellDing();
      setTimeout(() => {
        alert('New notifications received');
      }, 2000);
      this.okToDing = false;
      setTimeout(() => {
        this.okToDing = true; // ok to ding again after 5 minutes
      }, 5 * 60 * 1000);
    }
  }

  // set the status of a notification to "received"
  markAsReceived(userID: string, key: string | null) {
    console.log('mark as received', userID, key);
    if (key === null) {
      return;
    }
    const myNotificationRef = ref(
      this.rtdb,
      'notifications/' + userID + '/' + key
    );
    update(myNotificationRef, { status: 'received' });
  }

  playBellDing() {
    const audio = new Audio();
    audio.src = 'assets/audio/bell-ding.mp3';
    audio.load();
    audio.play();
  }

  playClosedDeal() {
    const audio = new Audio();
    audio.src = 'assets/audio/closed-deal.mp3';
    audio.load();
    audio.play();
  }

  updateDuration() {
    const nowDate = new Date();
    this.myNotifications.forEach((entry) => {
      console.log('update duration', nowDate, entry.updateTimestamp);
      let duration = humanizeDuration(
        nowDate.getTime() - entry.updateTimestamp
      );
      entry.duration = duration;
    });
  }
}
